<template>
  <div>

    <div v-if="!show404 && !show403">
      <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>

      <!-- Basic info block -->
      <BasicInfoBlock :object="process" @updated="updateProcess" :edit="edit"></BasicInfoBlock>

      <b-tabs class="mt-3" v-model="tabIndex">

        <!-- Basic info tab -->
        <b-tab :title="$t('processes.tab.basic_info')" active @click="basicTabClicked">
          <br />
          <div class="card" qid="basic-info-section">
            <!-- Update description -->
            <DescriptionBlock :object="process" @updated="updateProcess"></DescriptionBlock>
          </div>
          <br />
          <!-- Policies list -->
          <PoliciesAndProceduresTable :object="process" @updated="updateProcess" v-if="getClient.policyModule"></PoliciesAndProceduresTable>

        </b-tab>

        <!-- GDPR tab -->
        <b-tab :title="$t('processes.tab.gdpr')" class="mt-3" @click="gdprTabClicked" v-if="getClient.gdprModule">
          <div v-if="showGdprTab">
            <!-- Update purpose -->
            <div class="card" qid="basic-info-section">
              <PurposeBlock :object="process" @updated="updateProcess"></PurposeBlock>
            </div>
            <br />
            <!-- Legal basis table -->
            <LegalBasisTable :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></LegalBasisTable>
            <br />
            <!-- Information obligation list -->
            <InformationObligationTable :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></InformationObligationTable>
            <br />
            <Riskprofile :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></Riskprofile>
            <br />
            <Confidentiality :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></Confidentiality>
            <br />
            <Integrity :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></Integrity>
            <br />
            <Availability :object="process" @updated="updateProcess" v-if="getClient.gdprAdvancedModule"></Availability>
          </div>
        </b-tab>

        <!-- Risk scenarios tab -->
        <b-tab :title="$t('activities.tab.risk_management')" class="mt-3" @click="riskTabClicked" v-if="getClient.riskModule">
          <div v-if="showRiskTab">
            <RiskScenariosTable :object="process" @updated="updateProcess"></RiskScenariosTable>
          </div>
        </b-tab>

        <!-- Process assesments tab -->
        <b-tab :title="$t('systems.tab.assessments')" class="mt-3" @click="assessmentsTabClicked" v-if="getClient.assessmentModule">
          <AssessmentsTable :process="process" @updated="updateProcess"></AssessmentsTable>
        </b-tab>

        <!-- Process activities tab -->
        <b-tab :title="$t('processes.tab.activities')"
               class="mt-3"
               @click="activitiesTabClicked"
               v-if="isGranted([permissions.PROCESS_ACTIVITY_VIEW, permissions.PROCESS_ACTIVITY_VIEW_OWN], process)"
        >
          <div v-if="showActivitiesTab">
            <ActivitiesTable :object="process" @updated="updateProcess"></ActivitiesTable>
          </div>
        </b-tab>

        <!-- Process history tab -->
        <b-tab :title="$t('processes.tab.history')" class="mt-3" @click="historyTabClicked">
          <div v-if="showHistoryTab">
            <ProcessHistory :object="process"></ProcessHistory>
          </div>
        </b-tab>

      </b-tabs>
    </div>


    <div v-if="show404">
      <page404></page404>
    </div>

    <div v-if="show403">
      <page403></page403>
    </div>


  </div>
</template>

<script>
import piincHttp from '@/util/configureAxios.js'
import {config} from '@/config.js'
import ProcessEditForm from '@/views/Processes/ProcessEditForm.vue';
import Avatar from '@/components/Avatar.vue';
import ValidationList from '@/views/Validations/ValidationList.vue';
import ValidationForm from '@/views/Validations/ValidationForm.vue';
import PoliciesAndProceduresTable from '@/views/Processes/PoliciesAndProceduresTable.vue';
import LegalBasisTable from '@/views/Processes/LegalBasisTable.vue';
import InformationObligationTable from '@/views/Processes/InformationObligationTable.vue';
import BasicInfoBlock from '@/views/Processes/BasicInfoBlock.vue';
import DescriptionBlock from '@/views/Processes/DescriptionBlock.vue';
import PurposeBlock from '@/views/Processes/PurposeBlock.vue';
import Confidentiality from '@/views/Processes/Confidentiality.vue';
import Riskprofile from '@/views/Processes/Riskprofile.vue';
import Integrity from '@/views/Processes/Integrity.vue';
import Availability from '@/views/Processes/Availability.vue';
import ActivitiesTable from '@/views/Processes/ActivitiesTable.vue';
import ProcessHistory from '@/views/Processes/ProcessHistory.vue';
import RiskScenariosTable from '@/views/Processes/RiskScenariosTable.vue';
import ShowMoreModal from '@/components/ShowMoreModal.vue';
import AssessmentsTable from '@/views/Processes/AssessmentsTable.vue';
import page404 from '@/components/404block.vue';
import page403 from '@/components/403block.vue';


export default {
  name: 'ProcessView',
  data: function() {
    return {
      breadcrumbItems: [
        {
          text: this.$t('dashboard.title'),
          to: '/'
        },
        {
          text: this.$t('processes.title'),
          to: '/processing-activities'
        }
      ],
      descriptionLength: config.descriptionLength,
      showFullDescription: false,
      showFullPurpose: false,
      process: {},
      edit: this.$route.params.edit,
      selectedSort: '',
      selectedDirection: '',
      lbTableItems: [],
      lbLoading: true,
      lbValidationShow: false,

      itemToDelete: {},
      processGroups: [],
      tags: [],
      users: [],
      error: '',
      lbForm: {
        name: '',
        article_reason: '',
        links: []
      },
      inputLink: '',
      selectedArticle: [],
      articles: [],
      selectedFiles: [],
      showArticleDescription: false,
      selectedLB: {},
      viewLBModal: false,
      editLBModal: false,
      updateValidationList: false,
      selectedLBSort: '',
      selectedLBDirection: '',
      showDescriptionInput: false,
      showPurposeInput: false,
      processEditDescription: '',
      processEditPurpose: '',
      showActivitiesTab: false,
      showAssessmentsTab: false,
      showHistoryTab: false,
      showRiskTab: false,
      showGdprTab: false,
      buttonDisabled: false,
      myTasks: [],
      myGuides: [],
      tabIndex: 0,
      selectedItem: {},
      showTagsModal: false,
      show404: false,
      show403: false,
    }
  },
  computed: {
    getClient() {
      return this.$store.state.client
    },
    getTabIndex() {
      return this.$store.state.tabIndex
    },
    getReloadContent() {
      return this.$store.state.updateContent
    },
    getReloadPage() {
      return this.$store.state.updatePage
    }
  },
  watch: {
    // Watch when client changes in store and trigger api reload
    getClient: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.slug && oldValue.slug && newValue.slug !== oldValue.slug) {
        let self = this;
        setTimeout(function(){
          if (self.$route.name == 'processes.view') {
            self.goToList()
          }
        }, 800);
      } else {
        this.loadContent(true);
      }
    },
    getReloadContent: function () {
      if (!this.showOnTask) {
        if (this.$store.state.updatedObject && this.$store.state.updatedObject.id) {
          if (this.$store.state.updatedObject.id == this.process.id && this.$store.state.updatedObject.who_am_i == this.process.who_am_i) {
            this.process = this.$store.state.updatedObject
          }
        }
      }
    },
    getReloadPage: function () {
      if (this.$store.state.updatedObject && this.$store.state.updatedObject.id && this.$store.state.updatedObject.id == this.process.id && this.$store.state.updatedObject.who_am_i == this.process.who_am_i) {
        this.$router.push({ name: 'processes.view', params: { slug: this.$store.state.updatedObject.slug }, query: { task: this.$route.query.task }})
      } else {
        this.$router.push({ name: 'processes.view', params: { slug: this.process.slug }, query: { task: this.$route.query.task }})
      }
    },
    getTabIndex: function (newValue, oldValue) {
      this.handleUpdateTab(newValue)
    }
  },
  components: {
    ProcessEditForm,
    Avatar,
    ValidationList,
    ValidationForm,
    Integrity,
    Availability,
    Confidentiality,
    Riskprofile,
    ActivitiesTable,
    ProcessHistory,
    RiskScenariosTable,
    ShowMoreModal,
    AssessmentsTable,
    DescriptionBlock,
    PurposeBlock,
    PoliciesAndProceduresTable,
    LegalBasisTable,
    InformationObligationTable,
    BasicInfoBlock,
    page403,
    page404
  },
  methods: {
    goToList() {
      this.$router.push('/processing-activities')
    },
    openTagsModal(object) {
      this.selectedItem = object
      this.showTagsModal = true
    },
    closeTagsModal() {
      this.selectedItem = {}
      this.showTagsModal = false
    },
    getReducedTags(tags) {
      let reducedTags = []
      if (tags.length > 3) {
        reducedTags = _.cloneDeep(tags);
        reducedTags = reducedTags.slice(0, 3);
      } else {
        reducedTags = tags;
      }
      return reducedTags;
    },
    getTask(code) {
      let existingTask = _.find(this.myTasks, function(object) {
        return object.code == code
      });
      return existingTask
    },
    openToolbar(object) {
      this.$store.commit('storeTaskToolbarState', true);
      this.$store.commit('storeActiveTask', object);
    },
    getGuide(code) {
      let existingGuide = _.find(this.myGuides, function(object) {
        return object.scope == code && object.type == 'Process' && object.enabled
      });
      return existingGuide
    },
    loadGuides(code) {
      let self = this;
      let language = localStorage.getItem('lang')? localStorage.getItem('lang') : 'en';

      piincHttp.get('guides', { params:
        {
          per_page: 1,
          language: 'en',
          type: 'Process',
          scope: code,
          client: this.getClient.slug
        }
      }).then(function(response) {
        if (response.data.items.length) {
          self.myGuides.push(response.data.items[0])
        }
      }, function() {});
    },
    openHelpCenter(object) {
      let topElement = document.getElementById("app");
      if (topElement.classList.contains("guide-collapsed")) {
        topElement.classList.toggle("guide-collapsed");
      }
      if (!topElement.classList.contains("activity-collapsed")) {
        topElement.classList.toggle("activity-collapsed");
      }

      this.$store.commit('storeHelpCenterState', true);
      this.$store.commit('storeActiveGuideScope', object);
    },
    handleUpdateTab(response) {
      if (response == 'eu-gdpr') {
        this.tabIndex = 1
        this.gdprTabClicked()
      }
      if (response == 'risk-management') {
        let index = 2;

        if (!this.getClient.gdprAdvancedModule) {
          index = index - 1;
        }

        this.tabIndex = index
        this.riskTabClicked()
      }
      if (response == 'basic-info') {
        this.tabIndex = 0
        this.basicTabClicked()
      }
    },
    basicTabClicked() {
      this.showHistoryTab = false;
      this.showAssessmentsTab = false;
      this.showRiskTab = false;
    },
    updateProcess() {
      this.loadContent();
    },
    gdprTabClicked() {
      this.showHistoryTab = false;
      this.showGdprTab = true;
      this.showRiskTab = false;
      this.showAssessmentsTab = false;
    },
    activitiesTabClicked() {
      this.showActivitiesTab = true;
      this.showAssessmentsTab = false;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    assessmentsTabClicked() {
      this.showAssessmentsTab = true;
      this.showHistoryTab = false;
      this.showRiskTab = false;
    },
    riskTabClicked() {
      this.showRiskTab = true;
      this.showHistoryTab = false;
      this.showAssessmentsTab = false;
    },
    historyTabClicked() {
      this.showHistoryTab = true;
      this.showRiskTab = false;
      this.showAssessmentsTab = false;
    },
    downloadMedia(object){
      piincHttp.get('documents/' + object.id + '/download', {responseType: 'blob'}).then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.download = object.file_name;

        link.click();
        link.remove()
        window.URL.revokeObjectURL(url);
      }, function() {});
    },
    reloadContent(object) {
      // If slug is not changed, just reload content
      if (object.slug == this.process.slug) {
        // Remove last item in breadcrumbs, because it will be added again on load content
        this.breadcrumbItems.pop();
        this.loadContent(true);
      } else {
        this.$store.commit('storeUpdateTasks', true);
        // If slug changed, reload state with new param
        this.$router.push({ name: 'processes.view', params: { slug: object.slug }})
      }
    },
    updateValidation(validation) {
      // Event emmited from validation component, update active validation
      this.process.validation = validation
    },
    showConfirmDeleteModal(object) {
      this.itemToDelete = object
      this.$refs['delete-process'].show()
    },
    hideConfirmDeleteModal() {
      this.itemToDelete = {}
      this.$refs['delete-process'].hide()
    },
    confirmDeleteTP(object) {
      let self = this;
      piincHttp.delete('processes/' + object.slug).then(function() {
        self.hideConfirmDeleteModal();
        self.goToList();
        
        let objectP = {
          type: 'processes',
          filters: {}
        }
        self.$store.commit('storeActiveFilters', objectP);
        self.$store.commit('storeUpdateTasks', true);
        self.$store.commit('storeActiveTask', {});
        // Trigger toastr message after modal close
        self.$toastr('success', self.$t('processes.delete.success'))
      }, function() {});
    },
    loadContent(breadcrumbPush) {
      let self = this;
      piincHttp.get('processes/' + this.$route.params.slug, { params:
        {
          client: this.getClient.slug
        }
      }).then(function(response) {
        self.process = response.data;
        self.loadTasks('update_basic_info');
        if (breadcrumbPush) {
          // Push Third party name in breadcrumb array
          self.breadcrumbItems.push({text: self.process.name, active: true});
        }
      }, function(error) {
        if (error.status == 404) {
          self.show404 = true
        }
        if (error.status == 403) {
          self.show403 = true
        }
      });
    },
    loadTasks(code) {
      let self = this;
      if (this.getClient.projectModule) {
        piincHttp.get('projects/me/tasks', { params:
          {
            per_page: 1,
            status: 'pending',
            model_type: this.process.who_am_i,
            model_id: this.process.id,
            code: code,
            client: this.getClient.slug
          }
        }).then(function(response) {
          if (response.data.items.length) {
            self.myTasks.push(response.data.items[0])
          }
        }, function() {});
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('storeTabIndex', '');
    next()
  },
  created () {
    let self = this;
    if (this.getClient && this.getClient.slug) {
      this.loadContent(true);
    }

    if (this.$route.params.tabIndex && this.$route.params.validation) {
      setTimeout(function(){
        self.tabIndex = self.$route.params.tabIndex
        if (self.tabIndex == 1) {
          self.gdprTabClicked()
        }
        if (self.tabIndex == 2) {
          self.riskTabClicked()
        }
      }, 1000);
      setTimeout(function(){
        var element = document.getElementsByClassName(self.$route.params.validation);
        element[0].scrollIntoView(true);
        window.scrollBy(0, -100);
        element[0].classList.add("highlight-validation");
      }, 3000);
    }

    this.loadGuides('update_basic_info');
  }
}
</script>
